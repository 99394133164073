export const validations = {
    phoneNumber: /^\d{12}$/,
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    password: /^(?=.*[A-Z].*[A-Z]).{8}$/
}

export const phoneNumberValidation = (value) => {
    return validations.phoneNumber.test(value);
}

export const emailValidation = (value) => {
    return validations.email.test(value);
}

export const passwordValidation = (value) => {
    return validations.password.test(value);
}

export const repeatPasswordValidation = (password, repeatPassword) => password === repeatPassword;
