import * as actionTypes from "../ActionTypes";

export const postSignUpAction = (payload) => {
    return {
        type: actionTypes.SIGN_UP_REQUEST,
        payload: payload,
    };
};

export const postLoginAction = (payload) => {
    return {
        type: actionTypes.LOGIN_REQUEST,
        payload: payload,
    };
};

export const postEmailVerficationAction = (payload) => {
    return {
        type: actionTypes.EMAIL_VERIFICATION_REQUEST,
        payload: payload,
    };
};

export const postResendVerificationAction = (payload) => {
    return {
        type: actionTypes.RESEND_VERIFICATION_REQUEST,
        payload: payload,
    };
};
